import Layout from "../Component/Layout/Layout"
import MainSection from "../Component/MainSection"

const MainPage=()=>{
    return(
        <>
        <Layout>
            <MainSection/>
        </Layout>
        </>
    )
}

export default MainPage