/* eslint-disable jsx-a11y/anchor-is-valid */
const Header=()=>{
    return(
        <>
        <header id="tt-header">
  <div id="js-init-sticky">
    <div className="tt-holder-wrapper">
      <div className="tt-holder">
        <div className="tt-col-left">
          <a href="#" className="tt-logo tt-logo-alignment">
            <img src="/images/logos.png" width="30%" height="25%" alt="" />
          </a>
        </div>
        <div className="tt-col-center tt-col-wide tt-col-objects text-center justify-content-end">
          <div className="tt-col__item">
            <nav id="tt-nav">
              <ul style={{ gap: 50 }}>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#service">Services</a>
                </li>
                <li>
                  <a href="#contact">Contacts</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
        </>
    )
}

export default Header