import { Toaster } from "react-hot-toast";
import "./App.css";
import MainPage from "./page/MainPage";

function App() {
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="App">
        <MainPage />
      </div>
    </>
  );
}

export default App;
