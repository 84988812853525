/* eslint-disable jsx-a11y/alt-text */

import { useState } from "react";
import toast from "react-hot-toast";

/* eslint-disable jsx-a11y/anchor-is-valid */
const MainSection = () => {
  const [secure, setsecure] = useState("");
  const Verification = () => {
    if (secure) {
      toast.success("Thanks For Submission");
      setsecure('')
    } else {
      toast.error("Please Accept Terms & Condition");
    }
  };
  return (
    <>
      <main id="tt-pageContent">
        <div className="container-fluid">
          <div
            className="blurred-background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "75%",
              backgroundImage: 'url("/images/background.jpg")',
              backgroundPosition: "center center",
              backgroundSize: "cover",
              zIndex: -1,
            }}
          />
          <div className="row ml-5 mt-5">
            <div className="col-lg-12 ml-5  d-flex flex-column justify-content-start align-items-start">
              <div className="w-100 d-flex justify-content-start pt-5">
                <img src="/images/company.svg" alt="" style={{ width: 400 }} />
              </div>
              <h1 className="home-h1">
                FIND INTERNET &amp; ASSURED
                <br /> TECH PROVIDERS IN YOUR AREA
              </h1>
              <h3 className="pb-4">
                Best Internet and Tv providers in your area.
              </h3>
            </div>
          </div>
        </div>
        <div
          className="tt-position-relative tt-overflow-hidden pt-5 mt-5"
          id="prices"
        >
          <div
            className="lazyload tt-obj03-bg"
            data-bg="images/wrapper06.png"
          />
          <div className="section-indent mt-0">
            <div className="container container-fluid-lg">
              <div className="title-block text-center">
                <h6 className="title-block__title pink mt-5">
                  LET US PROVIDE YOU WITH THE GREATEST INTERNET AND TV SERVICE.
                </h6>
                <div className="title-block__label text-white mt-3 text-center">
                  Here at assured tech.com, we make it simple to find and
                  internet in your region
                  <br /> so you can go straight to the enjoyable part: owning TV
                  and internet.
                </div>
              </div>
              <div className="promo02__wrapper row js-init-carusel-tablet slick-default">
                <div className="tt-item col-md-4 my-3">
                  <div className="promo02 js-handler px-0 rounded">
                    <div className="w-100 d-flex justify-content-center">
                      <img
                        src="/images/im1.png"
                        alt=""
                        style={{ width: 130 }}
                      />
                    </div>
                    <div className="mt-3">
                      <h3>TV SERVICE</h3>
                    </div>
                    <div className="w-100 p-3">
                      <p>
                        Your preferred television programs and films are
                        available for viewing on assured tech, satellite TV, and
                        internet streaming options. To help you choose your
                        favorite method of tuning in, we'll break down each of
                        these TV alternatives. See TV Providers
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tt-item col-md-4 my-3">
                  <div className="promo02 js-handler px-0 rounded">
                    <div className="w-100 d-flex justify-content-center">
                      <img
                        src="/images/im2.png"
                        alt=""
                        style={{ width: 130 }}
                      />
                    </div>
                    <div className="mt-3">
                      <h3>INTERNET SERVICE</h3>
                    </div>
                    <div className="w-100 p-3">
                      <p>
                        You should have affordable internet that offers a ton of
                        data at fast download speeds. We examine and investigate
                        all of the main US internet providers so that your home
                        network will function flawlessly.See Internet Providers
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tt-item col-md-4 my-3">
                  <div className="promo02 js-handler px-0 rounded">
                    <div className="w-100 d-flex justify-content-center">
                      <img
                        src="/images/im3.png"
                        alt=""
                        style={{ width: 130 }}
                      />
                    </div>
                    <div className="mt-3">
                      <h3>COMPARE PROVIDERS</h3>
                    </div>
                    <div className="w-100 p-3">
                      <p>
                        You should be able to get internet at a great price that
                        offers gobs of data and high download speeds. We examine
                        and investigate all of the main US internet providers so
                        that your home network will function flawlessly.Compare
                        Providers
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tt-item col-md-4 my-3">
                  <div className="promo02 js-handler px-0 rounded">
                    <div className="w-100 d-flex justify-content-center">
                      <img
                        src="/images/im4.png"
                        alt=""
                        style={{ width: 130 }}
                      />
                    </div>
                    <div className="mt-3">
                      <h3>HELPFUL RESOURCES</h3>
                    </div>
                    <div className="w-100 p-3">
                      <p>
                        You should have access to affordable internet that
                        offers large data volumes and quick download speeds. To
                        ensure that your home Wi-Fi is fantastic, we investigate
                        and evaluate each of the main US internet providers.
                        Browse Articles
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-indent mt-5">
          <div className="container container-fluid-lg">
            <div className="title-block text-center">
              <h4 className="title-block__title mt-4 pink">
                LET'S START WATCHING!
              </h4>
              <div className="title-block__label text-white">
                Superfans, celebrate! Whether it's professional football, live
                news, or streaming original content, <br />
                our TV recommendations make it easy for you to enjoy the
                entertainment you love.
              </div>
            </div>
            <div className="row2 img-box__wrapper mt-4">
              <div className="col-custom-450 col-6 col-md-4">
                <div className="img-box">
                  <div className="img-box__img">
                    <a href="#">
                      <img
                        src="/images/user1.jpg"
                        className=" lazyloaded"
                        data-src="/images/img08.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <a href="#" className="img-box__title text-white">
                    How To Watch NFL Games 2023
                  </a>
                  <p className="text-white">
                    Are you prepared for a football game? Discover the top
                    methods for watching and streaming NFL games in 2023–2024,
                    along with important dates and channel listings.
                  </p>
                </div>
              </div>
              <div className="col-custom-450 col-6 col-md-4 custommg">
                <div className="img-box">
                  <div className="img-box__img">
                    <a href="#">
                      <img
                        src="/images/user2.jpg"
                        className=" lazyloaded"
                        data-src="/images/img08.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <a href="#" className="img-box__title text-white">
                    How To Watch Upcoming Awards Shows 2023
                  </a>
                  <p className="text-white">
                    See your preferred television program, movie, or artist with
                    our schedule of forthcoming award shows. Please don't worry
                    if anything is marked as TBD; we will provide an update as
                    soon as we learn more about the event.
                  </p>
                </div>
              </div>
            </div>
            <div className="row2 img-box__wrapper mt-4">
              <div className="col-custom-450 col-6 col-md-4">
                <div className="img-box">
                  <div className="img-box__img">
                    <a href="#">
                      <img
                        src="/images/user3.jpg"
                        className=" lazyloaded"
                        data-src="/images/img08.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <a href="#" className="img-box__title text-white">
                    How to Watch Live TV News for Free: ABC, CBS, FOX News, NBC,
                    and More
                  </a>
                  <p className="text-white">
                    "News travels even quicker than life itself. Fortunately,
                    there are several live TV news networks that you can watch
                    and stream for free during these exciting moments.
                  </p>
                </div>
              </div>
              <div className="col-custom-450 col-6 col-md-4 custommg">
                <div className="img-box">
                  <div className="img-box__img">
                    <a href="#">
                      <img
                        src="/images/user4.jpg"
                        className=" lazyloaded"
                        data-src="/images/img08.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <a href="#" className="img-box__title text-white">
                    How To Watch the NBA In-Season Tournament 2023
                  </a>
                  <p className="text-white">
                    Fans may expect a new gaming edge as the NBA gets ready for
                    its 78th season. The NBA In-Season Tournament, which begins
                    in November, is expected to provide another level of
                    interest to the first half of the regular season.
                  </p>
                </div>
              </div>
            </div>
            <div className="row2 img-box__wrapper mt-4">
              <div className="col-custom-450 col-6 col-md-4">
                <div className="img-box">
                  <div className="img-box__img">
                    <a href="#">
                      <img
                        src="/images/user5.jpg"
                        className=" lazyloaded"
                        data-src="/images/img08.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <a href="#" className="img-box__title text-white">
                    How To Watch Upcoming Sports Events 2023
                  </a>
                  <p className="text-white">
                    With our often updated TV sports calendar, you can
                    experience the thrills and chills of every big sporting
                    event in 2023
                  </p>
                </div>
              </div>
              <div className="col-custom-450 col-6 col-md-4 custommg">
                <div className="img-box">
                  <div className="img-box__img">
                    <a href="#">
                      <img
                        src="/images/user6.jpg"
                        className=" lazyloaded"
                        data-src="/images/img08.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <a href="#" className="img-box__title text-white">
                    TV Premieres November 2023
                  </a>
                  <p className="text-white">
                    With more than 100 new and returning TV series and films,
                    such as Only Murders In the Building, Billions, and
                    Physical, we're enjoying the dog days of summer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section-inner bg-top-left move-top-bottom tt-overflow-hidden lazyloaded "
          id="service"
        >
          <div className="ml-5">
            <h1 className="title-block__title pink ml-5">SERVICES</h1>
          </div>
          <h1 className="title-block__title pink text-center mt-5">
            TV PACKAGES
          </h1>
          <div className="container container-fluid-xl mt-3">
            <div className="box01 mt-5">
              <div
                className="box01__img rounded"
                style={{ backgroundImage: 'url("/images/ser1.jpg")' }}
              />
              <div className="box01__content">
                <div
                  style={{
                    borderLeft: "2px solid rgb(53, 197, 252)",
                    paddingLeft: 20,
                    height: 40,
                  }}
                />
                <div className="title-block mt-2">
                  <h4 className="title-block__title text-white">
                    TV packages starting at
                  </h4>
                </div>
                <h2
                  className="text-white"
                  style={{
                    borderLeft: "2px solid rgb(53, 197, 252)",
                    paddingLeft: 20,
                  }}
                >
                  $59.99/mo.
                </h2>
                <ul
                  className="font-weight-bold mt-5 gap-2"
                  style={{ listStyle: "disc", color: "white" }}
                >
                  <li>Up to 228+ channels with add-on packs</li>
                  <li>Comes with free Peacock Premium</li>{" "}
                  <li>Watch on the go with ’s TV app</li>{" "}
                  <li>No assured tech box requirements</li>
                </ul>
                <button type="button" className="btn planbt mt-4">
                  View Plans
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tt-position-relative tt-overflow-hidden pt-5 mt-2"
          id="prices"
        >
          <div
            className="lazyload tt-obj03-bg"
            data-bg="images/wrapper06.png"
          />
          <div className="section-indent mt-0">
            <div className="container container-fluid-lg">
              <div className="promo02__wrapper row js-init-carusel-tablet slick-default">
                <div className="tt-item col-md-4 my-3">
                  <div className="promo02 js-handler px-0 rounded">
                    <div
                      className="mt-4 text-center"
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                    >
                      <h3>TV Select Signature</h3>
                    </div>
                    <div className="w-100 p-3">
                      <h2
                        className=""
                        style={{
                          borderLeft: "2px solid rgb(53, 197, 252)",
                          paddingLeft: 20,
                        }}
                      >
                        $59.99/mo
                      </h2>
                      <ul
                        className="font-weight-bold mt-5 ml-4 gap-2"
                        style={{ listStyle: "disc" }}
                      >
                        <li>150+ channels</li>
                        <li>
                          Includes local and basic assured tech channels
                        </li>{" "}
                        <li>Channels include NBC, FX, and Magnolia Network</li>
                      </ul>
                      <button type="button" className="btn planbt mt-4">
                        View Plans
                      </button>
                    </div>
                  </div>
                </div>
                <div className="tt-item col-md-4 my-3">
                  <div className="promo02 js-handler px-0 rounded">
                    <div
                      className="mt-4 text-center"
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                    >
                      <h3>TV Select Signature + Entertainment View</h3>
                    </div>
                    <div className="w-100 p-3">
                      <h2
                        className=""
                        style={{
                          borderLeft: "2px solid rgb(53, 197, 252)",
                          paddingLeft: 20,
                        }}
                      >
                        $71.99/mo
                      </h2>
                      <ul
                        className="font-weight-bold mt-5 ml-4 gap-2"
                        style={{ listStyle: "disc" }}
                      >
                        <li>205+ channels</li>
                        <li>Adds 80+ basic assured tech channels</li>{" "}
                        <li>
                          Channels include Disney XD, Cooking Channel, and OWN
                        </li>
                      </ul>
                      <button type="button" className="btn planbt mt-4">
                        View Plans
                      </button>
                    </div>
                  </div>
                </div>
                <div className="tt-item col-md-4 my-3">
                  <div className="promo02 js-handler px-0 rounded">
                    <div
                      className="mt-4 text-center"
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                    >
                      <h3>
                        TV Select Signature + Sports View + Entertainment View
                      </h3>
                    </div>
                    <div className="w-100 p-3">
                      <h2
                        className=""
                        style={{
                          borderLeft: "2px solid rgb(53, 197, 252)",
                          paddingLeft: 20,
                        }}
                      >
                        $77.99/mo
                      </h2>
                      <ul
                        className="font-weight-bold mt-5 ml-4 gap-2"
                        style={{ listStyle: "disc" }}
                      >
                        <li>228+ channels</li>
                        <li>Adds premium live sports networks</li>{" "}
                        <li>
                          Channels include NFL RedZone, ESPNews, Outdoor Channel
                        </li>
                      </ul>
                      <button type="button" className="btn planbt mt-4">
                        View Plans
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt-4">
                <div className="row gap-2 d-flex align-items-center justify-content-center">
                  <div className="col-10">
                    <input
                      className="w-100 mt-3 zip p-2 rounded"
                      placeholder="Zip Code"
                      style={{ border: "none" }}
                    />
                  </div>
                  <div className="col-2">
                    <button className="planbt2">FIND</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section-inner bg-top-left move-top-bottom tt-overflow-hidden lazyloaded "
          id="service"
        >
          <h1 className="title-block__title pink text-center mt-5">
            INTERNET PLANS, PACKAGES, AND
            <br />
            <br /> PRICES REVIEW
          </h1>
          <h3 className="title-block__title text-white text-center mt-3">
            internet plans go as low as $49.99 per month for 300 Mbps.
          </h3>
          <div className="container container-fluid-xl mt-5 d-flex justify-content-center">
            <img src="images/first.jpeg" />
          </div>
        </div>
        <div
          className="section-inner bg-top-left move-top-bottom tt-overflow-hidden lazyloaded  "
          id="service"
        >
          <h1 className="title-block__title pink text-center mt-5">
            TV PACKAGES, PRICES, AND
            <br /> <br />
            PLANS REVIEW
          </h1>
          <h3 className="title-block__title text-white text-center mt-3">
            offers one base TV package: 150+ channels for $59.99/mo. Read on to
            learn about bundles and add-ons.
          </h3>
          <div className="container container-fluid-xl mt-5 d-flex justify-content-center">
            <img src="images/second.jpeg" />
          </div>
        </div>
        <div
          className="section-inner bg-top-left move-top-bottom tt-overflow-hidden lazyloaded "
          id="service"
        >
          <h1 className="title-block__title pink text-center mt-5">
            CHEAPEST INTERNET® PLAN
          </h1>
          <h3 className="title-block__title text-white text-center mt-3">
            Want to save on Internet®? Here’s how you can get the best deals
          </h3>
          <div className="container container-fluid-xl mt-5 d-flex justify-content-center">
            <img src="images/third.jpeg" />
          </div>
        </div>
        <div className="tt-posirion-relative pt-5" id="contact">
          <div className="container container-fluid-lg">
            <div className="title-block text-center">
              <h4 className="title-block__title  pink">CONTACT US</h4>
              <div className="title-block__label text-white">
                As you might expect of a company that began as a high-end
                interiors contractor, we pay strict attention.
              </div>
            </div>
            <div
              className="title-block__label text-white"
              style={{ marginLeft: 94 }}
            >
              Email
            </div>
            <div
              className="title-block__label text-white"
              style={{ marginLeft: 94 }}
            >
              Click more detail &amp; information{" "}
              <a href="mailto:info@assuredtechno.com" className="text-primary">
              info@assuredtechno.com
              </a>
            </div>
            <div
              className="title-block__label text-white"
              style={{ marginLeft: 94 }}
            />
            <div className="row">
              <div className="col-lg-12">
                <div className="map-layout">
                  <div className="form-default" id="contact-form">
                    <div className="row row-align-col">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="phonenumber"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                    </div>
                    <input
                      type="number"
                      name="email"
                      className="form-control"
                      placeholder="Phone Number"
                    />
                    <div className="form-group mt-2">
                      <textarea
                        name="message"
                        rows={7}
                        className="form-control"
                        placeholder="Your message"
                        defaultValue={""}
                      />
                    </div>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value={"accept"}
                      checked={secure?true:false}
                      onChange={(e) => setsecure(e.target.value)}
                    />
                    <label for="vehicle1" className="text-white ml-2">
                    Would you be willing to accept a call?
                    </label>
                    <div className="">
                      <button
                        // type="submit"
                        onClick={() => Verification()}
                        className="button btn planbt"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default MainSection;
