/* eslint-disable jsx-a11y/anchor-is-valid */
const Footer=()=>{
    return(
        <>
        <div className="footer">
  <div className="container">
    <div className="row">
      <div className="col-lg-4 col-sm-4 col-xs-12">
        <div className="single_footer">
          <h4 className="pink">TV &amp; Internet Providers</h4>
          <ul>
            <li>
              <a href="#service">Shop TV</a>
            </li>
            <li>
              <a href="#service">Internet Review</a>
            </li>
            <li>
              <a href="#service">Tv review</a>
            </li>
            <li>
              <a href="#service">Cheapest Internet</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="single_footer single_footer_address">
          <h4 className="pink">Local</h4>
          <ul>
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li>
              <a href="#">Home</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="single_footer single_footer_address">
          <h4 className="pink">Find Providers in Your Area</h4>
          <div className="signup_form">
            <form action="#" className="subscribe">
              <input
                type="text"
                className="subscribe__input"
                placeholder="Zip Code"
              />
              <button type="button" className="planbt btnpink">
                FIND
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="">
        <p className="copyright">© 2010-2023 Web Link. All rights reserved.</p>
      </div>
    </div>
  </div>
</div>
        </>
    )
}

export default Footer